.list-group-item-transparent {
  @include list-group-item-variant(transparent, transparent, $list-group-color);
  color: $dark;

  &.active {
    font-weight: bold;
    background-color: transparent;
    color: $dark;
  }

  &:hover {
    background-color: darken($list-group-item-transparent-active-base-bg, 7.5%);
  }
}

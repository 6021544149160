#available-operator-list > * {
  padding: 2px;
}
.canvas {
  border: thin dashed black;
  border-radius: 4px;
  margin-top: 8px;
  min-height: 150px;
  overflow-x: auto;

  .btn-outline-primary {
    background-color: white;
  }

  .btn-outline-primary:hover {
    color: #223555;
  }

  & .container,
  & .col {
    padding-left: 3px;
    padding-right: 3px;
  }

  & .container {
    display: inline-table;
    padding: 15px;
  }

  & .row {
    align-items: center;
    margin-left: -3px;
    margin-right: -3px;
    padding-top: 5px;
    padding-bottom: 5px;
    .term {
      width: 100%;
    }
  }

  & .col {
    justify-content: center;
    text-align: center;
  }

  /**
   * @see https://stackoverflow.com/a/70173007/1757334
   */
  @mixin altBox($n) {
    $n: $n + 1;

    .container {
      @if $n % 2 == 0 {
        background: gainsboro;
      } @else {
        background: white;
      }

      @if ($n < 9) {
        @include altBox($n);
      }
    }
  }
  @include altBox(0);
}

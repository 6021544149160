$opacity-5: rgba(255, 255, 255, 0.5);
$padding-base-horizontal: 0.7rem;
$padding-base-vertical: 0.5rem;
$sidebar-width: 18.75rem;
$main-panel-width: calc(100% - #{$sidebar-width});
$sidebar-mini-width: 5rem;
$main-panel-mini-width: calc(100% - #{$sidebar-mini-width});
$general-transition-time: 300ms;
$fast-transition-time: 150ms;
$transition-ease: ease 0s;

.sidebar .nav a,
.sidebar .nav a i,
.animation-transition-general {
  @include transition($general-transition-time, $transition-ease);
}

.wrapper {
  position: relative;
  top: 0;
  height: 100vh;

  &.wrapper-full-page {
    min-height: 100vh;
    height: auto;
  }
}

.sidebar {
  position: fixed;
  top: 0;
  height: 100%;
  bottom: 0;
  width: $sidebar-width;
  left: 0;
  z-index: 999;

  .sidebar-wrapper {
    position: relative;
    height: calc(100vh - 4.688rem);
    overflow: auto;
    width: $sidebar-width;
    z-index: 4;
    padding-bottom: 6.25rem;

    .dropdown .dropdown-backdrop {
      display: none !important;
    }

    .navbar-form {
      border: none;
    }

    > .nav {
      [data-toggle='collapse'] ~ div > div > div > li > a {
        span {
          display: inline-block;
          @extend .animation-transition-general;
        }

        .sidebar-normal {
          margin: 0;
          position: relative;
          @include transform-translate-x(0rem);
          text-decoration: none;
          opacity: 1;
          white-space: nowrap;
          display: block;
          line-height: 1.25rem;
          z-index: 1;
        }

        .sidebar-mini-icon {
          text-transform: uppercase;
          width: 2.125rem;
          margin-right: 0.625rem;
          font-size: 0.75rem;
          text-align: center;
          line-height: 1.25rem;
          position: relative;
          float: left;
          z-index: 1;
          display: inherit;
          color: $opacity-5;
          margin-left: 10px;
        }

        .sidebar-normal {
          text-transform: capitalize;
        }
      }
    }
  }

  .navbar-minimize {
    position: absolute;
    right: 1.25rem;
    top: 0.125rem;
    opacity: 1;

    @extend .animation-transition-general;
  }

  .nav {
    margin-top: 1.25rem;
    display: block;

    ul {
      > button + div .nav li > a {
        margin-top: 0.438rem;
        text-decoration: none;
      }

      button {
        padding: 0.625rem 0.938rem 0 !important;
        color: $white;
        display: block;
        text-decoration: none;
        position: relative;
        text-transform: uppercase;
        cursor: pointer;
        font-size: 0.75rem;
        padding: 0.625rem 0.5rem;
        line-height: 1.875rem;
        opacity: 0.7;
      }

      .nav > li > a {
        padding: 0.313rem 0.5rem;
      }

      &.active > button,
      &.active > button > svg {
        opacity: 1;
      }

      &:hover:not(.active) > button,
      &:focus:not(.active) > button {
        opacity: 1;
      }

      &.active > button[data-toggle='collapse'] {
        background-color: transparent;
      }

      &.active > button[data-toggle='collapse'],
      &.active > button[data-toggle='collapse'] i,
      &.active > button[data-toggle='collapse'] ~ div > div > div > li.active i,
      &.active > button[data-toggle='collapse'] ~ div > div > div > li.active > a {
        color: $danger;

        & + div .nav .active a {
          background-color: transparent;
          box-shadow: none;

          &:after {
            content: '';
            position: absolute;
            width: calc(100% - 0.313rem);
            z-index: 0;
          }
        }
      }

      &.active > button[data-toggle='collapse'] ~ div > div > .nav {
        margin-top: 0;
      }
    }

    p {
      margin: 0;
      line-height: 1.875rem;
      position: relative;
      display: block;
      height: auto;
      white-space: nowrap;
      font-weight: 600;
      @extend .animation-transition-general;
    }

    i {
      font-size: 1.5rem;
      float: left;
      margin-right: 0.75rem;
      line-height: 1.875rem;
      width: 2.125;
      text-align: center;
      color: $opacity-5;
      position: relative;
    }

    .collapse,
    .collapsing {
      .nav {
        margin-top: 0;
      }
    }
  }

  .sidebar-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;

    &:after {
      position: absolute;
      z-index: 3;
      width: 100%;
      height: 100%;
      content: '';
      display: block;
      background: #ffffff;
      opacity: 1;
    }
  }

  .logo {
    position: relative;
    padding: 0.438rem $padding-base-horizontal;
    z-index: 4;

    a.logo-mini,
    a.logo-normal {
      @extend .animation-transition-general;
    }

    a.logo-mini {
      opacity: 0;
      float: left;
      width: 2.125rem;
      text-align: center;
      margin-left: 0.625rem;
      margin-right: 0.75rem;
    }

    a.logo-normal {
      display: block;
      opacity: 1;
      padding: 0.688rem 0 0.5rem;
      @include transform-translate-x(0);
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0.938rem;
      height: 0.063rem;
      width: calc(100% - 1.875rem);
      background-color: $opacity-5;
    }

    p {
      float: left;
      font-size: 1.25rem;
      margin: 0.625rem 0.625rem;
      color: $white;
      line-height: 1.25rem;
      font-family: 'Muli', 'Helvetica', Arial, sans-serif;
    }

    .simple-text {
      text-transform: uppercase;
      padding: $padding-base-vertical 0;
      display: block;
      white-space: nowrap;
      font-size: $font-size-lg;
      color: $white;
      text-decoration: none;
      font-weight: $font-weight-normal;
      line-height: 1.875rem;
      overflow: hidden;
    }
  }

  &:before,
  &:after {
    display: block;
    content: '';
    opacity: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &:after {
    background: linear-gradient($body-color 0%, #000 80%);
    z-index: 3;
  }

  &[data-color='white'] {
    &:after {
      background: $white;
    }
    .nav {
      ul {
        a,
        a i,
        button[data-toggle='collapse'],
        button[data-toggle='collapse'] i,
        button[data-toggle='collapse'] ~ div > div > div > li > a .sidebar-mini-icon,
        button[data-toggle='collapse'] ~ div > div > div > li > a {
          color: $body-color;
          opacity: 0.7;
        }

        &:hover:not(.active) > a,
        &:focus:not(.active) > a {
          opacity: 1;
        }
      }
    }

    .logo {
      .simple-text {
        color: $body-color;
      }
      &:after {
        background-color: $body-color;
        opacity: 0.4;
      }
    }
  }

  &[data-active-color='primary'] {
    .nav {
      ul {
        &.active > a,
        &.active > a i,
        &.active > button[data-toggle='collapse'],
        &.active > button[data-toggle='collapse'] i,
        &.active
          > button[data-toggle='collapse']
          ~ div
          > div
          > div
          > li.active
          > a
          .sidebar-mini-icon,
        &.active > button[data-toggle='collapse'] ~ div > div > div > li.active > a {
          color: $primary;
          opacity: 1;
        }
      }
    }
  }
}

.main-panel {
  position: relative;
  float: right;
  width: $main-panel-width;
  background-color: #f4f3ef;
  overflow-y: auto;
  overflow-x: hidden;

  @include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));

  > .content {
    padding: 0 1.875rem 1.875rem;
    min-height: calc(100vh - 7.688rem);
    margin-top: 5.813rem;
  }

  > .navbar {
    margin-bottom: 0;
  }

  .header {
    margin-bottom: 3.125rem;
  }
}

.perfect-scrollbar-on {
  .sidebar,
  .main-panel {
    height: 100%;
    max-height: 100%;
  }
}

@media (min-width: 991px) {
  .sidebar,
  .main-panel,
  .sidebar-wrapper {
    -webkit-transition-property: top, bottom, width;
    transition-property: top, bottom, width;
    -webkit-transition-duration: 0.2s, 0.2s, 0.35s;
    transition-duration: 0.2s, 0.2s, 0.35s;
    -webkit-transition-timing-function: linear, linear, ease;
    transition-timing-function: linear, linear, ease;
    -webkit-overflow-scrolling: touch;
  }

  .sidebar-mini {
    .sidebar,
    .sidebar .sidebar-wrapper {
      width: $sidebar-mini-width;
    }

    .main-panel {
      width: $main-panel-mini-width;
    }

    .sidebar {
      display: block;
      z-index: 1030;

      .logo {
        a.logo-normal {
          opacity: 0;
          @include transform-translate-x(-1.563rem);
        }
        a.logo-mini {
          opacity: 1;
        }
      }

      .sidebar-wrapper {
        > .nav [data-toggle='collapse'] ~ div > div > div > li > a .sidebar-normal,
        > .nav ul > button p {
          @include transform-translate-x(-1.563rem);
          opacity: 0;
          width: 0px !important;
        }
      }
    }

    .sidebar:hover {
      width: $sidebar-width;

      .logo {
        a.logo-normal {
          opacity: 1;
          @include transform-translate-x(0);
        }
      }

      .navbar-minimize {
        opacity: 1;
      }
      .sidebar-wrapper {
        width: $sidebar-width;

        > .nav ul > button p,
        > .nav [data-toggle='collapse'] ~ div > div > div > li > a .sidebar-normal {
          @include transform-translate-x(0rem);
          opacity: 1;
          width: 100% !important;
        }

        > .nav [data-toggle='collapse'] ~ div > div > div > li > a .sidebar-mini-icon {
          width: 2.125rem;
        }
      }
    }
  }
}

.panel-header {
  height: $sidebar-width;
  padding-top: $sidebar-mini-width;
  padding-bottom: 2.813rem;
  background: #141e30; /* fallback for old browsers */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#0c2646),
    color-stop(60%, #204065),
    to(#2a5788)
  );
  background: linear-gradient(to right, #0c2646 0%, #204065 60%, #2a5788 100%);
  position: relative;
  overflow: hidden;

  .header {
    .title {
      color: $white;
    }
    .category {
      max-width: 37.5rem;
      color: $opacity-5;
      margin: 0 auto;
      font-size: 0.813rem;

      a {
        color: $white;
      }
    }
  }
}

.panel-header-sm {
  height: 8.438rem;
}

.panel-header-lg {
  height: 23.75rem;
}

// Stying for logos

.sidebar .logo .simple-text .logo-img {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 2.125rem;
  width: 2.125rem;
  background: #ffffff;
  border-radius: 50%;
  text-align: center;
  overflow: hidden;

  img {
    width: 51%;
    vertical-align: unset;
    height: 3.438rem;
  }
}

.sidebar {
  &[data-color='white'] {
    border-right: 0.063rem solid #ddd;
  }
}

@include media-breakpoint-down(md) {
  .sidebar,
  .bootstrap-navbar {
    position: fixed;
    display: block;
    top: 0;
    height: 100%;
    width: $sidebar-width;
    right: auto;
    left: 0;
    z-index: 1032;
    visibility: visible;
    overflow-y: visible;
    padding: 0;
    @include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));

    @include transform-translate-x(-$sidebar-width);
  }

  .main-panel {
    width: 100%;
  }

  .sidebar {
    .sidebar-wrapper {
      ul.active {
        > button:not([data-toggle='collapse']),
        > [data-toggle='collapse'] + div .nav li {
          &:before {
            border-right: 1.063rem solid $gray-500;
            border-top: 1.063rem solid transparent;
            border-bottom: 1.063rem solid transparent;
            content: '';
            display: inline-block;
            position: absolute;
            right: -1rem;
            opacity: 1;
            top: 0.438rem;
            transition: opacity 150ms ease-in;
          }

          &:after {
            border-right: 1.063rem solid $body-bg;
            border-top: 1.063rem solid transparent;
            border-bottom: 1.063rem solid transparent;
            content: '';
            display: inline-block;
            position: absolute;
            right: -1.063rem;
            opacity: 1;
            top: 0.438rem;
            transition: opacity 150ms ease-in;
          }
        }
        > [data-toggle='collapse'] + div .nav li {
          a {
            &:before,
            &:after {
              top: 0;
            }
          }
        }
      }
    }
  }

  .sidebar-mini {
    .main-panel {
      right: 0;
      @include transform-translate-x($sidebar-width);
    }

    .sidebar {
      @include transform-translate-x(0rem);
    }
  }
}

$card-grid-padding: calc((map-get($spacers, 3) + map-get($spacers, 2)) / 2);

.card-grid {
  display: grid;
  overflow: hidden;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-gap: $card-grid-padding;
  padding: $card-grid-padding;

  & .card {
    margin-bottom: 0;
  }
}

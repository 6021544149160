// Alert sizes
@mixin alert-size(
  $padding-y,
  $padding-x,
  $font-size,
  $line-height,
  $border-radius,
  $close-font-size
) {
  padding: $padding-y $padding-x;
  @include font-size($font-size);
  line-height: $line-height;
  // Manually declare to provide an override to the browser default
  @include border-radius($border-radius, 0);

  &.alert-dismissible {
    padding-right: $close-font-size + $padding-x * 2;

    // Adjust close link position
    .close {
      font-size: $close-font-size;
      padding: $padding-y $padding-x;
    }
  }

  &.alert-center {
    @include alert-center($padding-x);
  }
}

// Center alerts

@mixin alert-center($padding-x) {
  text-align: center;

  &.alert-dismissible {
    padding-left: $close-font-size + $padding-x * 2;
  }
}

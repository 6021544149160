.btn-transparent {
  @include button-variant(
    $background: transparent,
    $border: transparent,
    $hover-background: darken($btn-transparent-active-base-bg, 7.5%),
    $hover-border: transparent,
    $active-background: darken($btn-transparent-active-base-bg, 10%),
    $active-border: transparent
  );

  color: $body-color;

  &.disabled,
  &:disabled {
    color: $body-color;
  }
}

.btn-xs {
  @include button-size(
    $btn-padding-y-xs,
    $btn-padding-x-xs,
    $btn-font-size-xs,
    $btn-border-radius-xs
  );
}

.btn-sm {
  @include button-size(
    $btn-padding-y-sm,
    $btn-padding-x-sm,
    $btn-font-size-sm,
    $btn-border-radius-sm
  );
}

.btn-xl {
  @include button-size(
    $btn-padding-y-xl,
    $btn-padding-x-xl,
    $btn-font-size-xl,
    $btn-border-radius-xl
  );
}

.btn-pointer-hover {
  &:hover {
    cursor: pointer;
  }
}

.btn-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &.btn {
    padding: $btn-padding-y;
  }

  &.btn-xl {
    padding: $btn-padding-y-xl;
  }

  &.btn-lg {
    padding: $btn-padding-y-lg;
  }

  &.btn-sm {
    padding: $btn-padding-y-sm;
  }

  &.btn-xs {
    padding: $btn-padding-y-xs;
  }
}

.btn-hover {
  &:hover {
    background: $gray-100;
  }
}

button.dropdown-toggle[aria-expanded='false']::after {
  transform: rotate(0deg);
}

button.dropdown-toggle[aria-expanded='true']::after {
  transform: rotate(180deg);
}

@mixin circle-size($diameter) {
  width: $diameter;
  height: $diameter;
}

$circle-box-shadow-width: 0.15rem;

@mixin circle-variant($background, $border) {
  box-shadow: 0 0 0 $circle-box-shadow-width
    rgba(mix(color-contrast($background), $border, 15%), 0.5);
}

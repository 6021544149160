@mixin switch-variant($switch-bg) {
  $custom-control-indicator-variant-focus-box-shadow: 0 0 0 $input-btn-focus-width
    rgba($switch-bg, 0.25);

  .form-check-input {
    &:not(:checked) {
      background-color: $white;
    }

    &:checked {
      color: $switch-bg;
      background-color: $switch-bg;
      border-color: $switch-bg;
      @include gradient-bg($switch-bg);
      @include box-shadow($custom-control-indicator-variant-focus-box-shadow);
    }

    &:focus {
      // the mixin is not used here to make sure there is feedback
      @if $enable-shadows {
        box-shadow: $input-box-shadow, $custom-control-indicator-variant-focus-box-shadow;
      } @else {
        box-shadow: $custom-control-indicator-variant-focus-box-shadow;
      }
    }

    &:not(:disabled):active {
      color: $switch-bg;
      background-color: $switch-bg;
      border-color: $switch-bg;
    }
  }
}

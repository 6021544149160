@keyframes skeleton-wave {
  0% {
    background-position: -$skeleton-bg-width 0;
  }
  100% {
    background-position: calc(#{$skeleton-bg-width} + #{$skeleton-bg-height}) 0;
  }
}

.skeleton {
  background-color: $skeleton-bg;
  background-image: linear-gradient(90deg, $skeleton-bg, $skeleton-highlight-bg, $skeleton-bg);
  background-size: $skeleton-bg-width $skeleton-bg-height;
  background-repeat: no-repeat;
  border-radius: $skeleton-border-radius;
  display: inline-block;
  line-height: 1;
  width: 100%;
  animation: skeleton-wave $skeleton-animation-duration ease-in-out infinite;
}

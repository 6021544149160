// Sidebar Logo

.logo-mini,
.logo-normal {
  height: $sidebar-logo-height;
}

.logo-wrapper {
  position: relative;
  transition: $sidebar-logo-transition;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: white;
    transition: $sidebar-logo-transition;
  }
}

.logo-wrapper-mini {
  left: $sidebar-logo-mini-offset-expanded;
  z-index: 2;

  &::after {
    bottom: $sidebar-logo-height - $sidebar-logo-mini-width;
    left: -100%;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    z-index: -1;
  }
}

.logo-wrapper-main {
  right: $sidebar-logo-main-offset-expanded;

  &::after {
    left: 100%;
  }
}

.sidebar-mini {
  .sidebar:hover {
    .logo-wrapper-mini {
      left: $sidebar-logo-mini-offset-expanded;
    }

    .logo-wrapper-main {
      &::after {
        left: 100%;
      }
    }
  }

  .logo-wrapper-mini {
    left: $sidebar-logo-mini-offset-collapsed;
  }

  .logo-wrapper-main {
    &::after {
      left: 0;
    }
  }
}

@include media-breakpoint-down(md) {
  .sidebar-mini {
    .logo-wrapper-mini {
      left: $sidebar-logo-mini-offset-expanded;
    }

    .logo-wrapper-main {
      &::after {
        left: 100%;
      }
    }
  }
}

// Sidebar Image Nav Link

.image-nav-link {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: $sidebar-image-nav-link-margin-y;
  padding-bottom: $sidebar-image-nav-link-margin-y;
  width: calc(100% - #{$sidebar-image-nav-link-margin-x * 2});
  margin-left: $sidebar-image-nav-link-margin-x;
  border-bottom: $sidebar-image-nav-link-border-width solid $sidebar-image-nav-link-border-color;
}

.image-nav-link-logo {
  width: $sidebar-image-nav-link-logo-width;
  height: $sidebar-image-nav-link-logo-height;
  margin-right: $sidebar-image-nav-link-logo-margin-right;
  left: $sidebar-image-nav-link-logo-offset-left;
  flex-shrink: 0;
  position: relative;
  z-index: 1;

  img {
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
    height: 100%;
    overflow: hidden;

    &:after {
      content: '';
      font-size: 0.063rem;
      color: $white;
      display: block;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $white;
    }
  }
}

.image-nav-link-name {
  white-space: normal;
  width: $sidebar-width - $sidebar-image-nav-link-logo-width -
    $sidebar-image-nav-link-logo-margin-right - ($sidebar-image-nav-link-margin-x * 2);
  font-size: $sidebar-image-nav-link-name-font-size;
  flex-shrink: 0;
  opacity: 1;
  position: absolute;
  left: $sidebar-image-nav-link-logo-width + $sidebar-image-nav-link-margin-x +
    $sidebar-image-nav-link-logo-margin-right;
  @include transform-translate-x(0);
  @extend .animation-transition-general;
}

.sidebar-mini {
  .image-nav-link-name {
    opacity: 0;
    @include transform-translate-x($sidebar-image-nav-link-name-translate-x);
  }

  .image-nav-link-logo {
    margin-right: 0;
  }

  .sidebar:hover {
    .image-nav-link-name {
      opacity: 1;
      @include transform-translate-x(0);
    }

    .image-nav-link-logo {
      margin-right: $sidebar-image-nav-link-logo-margin-right;
    }
  }
}

@include media-breakpoint-down(md) {
  .sidebar-mini {
    .image-nav-link-name {
      opacity: 1;
      @include transform-translate-x(0);
    }
  }
}

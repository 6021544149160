.alert {
  @include alert-size(
    $padding-y: $alert-padding-y,
    $padding-x: $alert-padding-x,
    $font-size: $alert-font-size,
    $line-height: $alert-line-height,
    $border-radius: $alert-border-radius,
    $close-font-size: $alert-close-font-size
  );
}

.alert-sm {
  @include alert-size(
    $padding-y: $alert-padding-y-sm,
    $padding-x: $alert-padding-x-sm,
    $font-size: $alert-font-size-sm,
    $line-height: $alert-line-height-sm,
    $border-radius: $alert-border-radius-sm,
    $close-font-size: $alert-close-font-size-sm
  );
}

.alert-center {
  @include alert-center($alert-padding-x);
}

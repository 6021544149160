// Horizontal navs

.nav-tabs {
  .nav-link {
    color: $nav-tabs-link-color;
  }

  .nav-item {
    position: relative;
  }

  .nav-link.active,
  .nav-link.active:hover,
  .nav-link.active:focus {
    &:before,
    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      top: auto;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: $nav-link-indicator-size;
      border-left: $nav-link-indicator-size solid rgba(0, 0, 0, 0);
      border-right: $nav-link-indicator-size solid rgba(0, 0, 0, 0);
    }

    &:before {
      border-bottom: $nav-link-indicator-size solid $nav-tabs-border-color;
      bottom: 0;
    }

    &:after {
      border-bottom: $nav-link-indicator-size solid $white;
      bottom: -0.063rem;
    }
  }
}

// Vertical navs

.nav-tabs.nav-stacked {
  border-bottom: 0;
  border-right: $nav-tabs-border-width solid $nav-tabs-border-color;

  .nav-link.active,
  .nav-link.active:hover,
  .nav-link.active:focus {
    &:before,
    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      bottom: 0;
      top: 0;
      left: auto;
      margin: auto 0;
      height: $nav-link-indicator-size;
      border-top: $nav-link-indicator-size solid transparent;
      border-bottom: $nav-link-indicator-size solid transparent;
    }

    &:before {
      border-right: $nav-link-indicator-size solid $nav-tabs-border-color;
      right: 0;
    }

    &:after {
      border-right: $nav-link-indicator-size solid $white;
      right: -0.063rem;
    }
  }
}

// Note: Once we get Bootstrap v5 support, we should utilize their utility api for generating utility classes. (https://getbootstrap.com/docs/5.0/utilities/api/)

.left-initial {
  left: initial;
}

.white-space-pre-wrap {
  white-space: pre-wrap;
}

.flex-basis-35px {
  flex-basis: 2.1875rem;
}

$z-indexes: (
  popover: $zindex-popover,
);

@each $z-index, $z-index-value in $z-indexes {
  .z-index-#{$z-index} {
    z-index: $z-index-value;
  }
}

@each $font-size, $font-size-value in $font-sizes {
  .font-size-#{$font-size} {
    font-size: $font-size-value;
  }
}

@each $icon-size, $icon-sizes-value in $icon-sizes {
  .icon-size-#{$icon-size} {
    font-size: $icon-sizes-value;
  }
}

$touch-actions: (
  pan-y: pan-y,
);

@each $touch-action-key, $touch-action-value in $touch-actions {
  .touch-action-#{$touch-action-key} {
    touch-action: $touch-action-value;
  }
}

$overflows: (
  auto: auto,
);

@each $overflow-key, $overflow-value in $overflows {
  .overflow-y-#{$overflow-key} {
    overflow-y: $overflow-value;
  }
}

$flexs: (
  even: 1,
);

@each $flex-key, $flex-value in $flexs {
  .flex-#{$flex-key} {
    flex: $flex-value;
  }
}

$icon-sizes: (
  32: 2rem,
);

@each $icon-size-key, $icon-size-value in $icon-sizes {
  .icon-#{$icon-size-key} {
    height: $icon-size-value;
    width: $icon-size-value;
  }
}

.task-profile-detail {
  .modal-title {
    font-size: 1rem;
  }

  .verical-navs .nav-tabs-wrapper {
    margin-right: 1.5rem;
  }

  .list-group-item + .list-group {
    padding-left: 1.5rem;

    > .list-group-item {
      padding-top: 0;
    }

    > .list-group-item:last-child {
      padding-bottom: 0;
    }
  }
}

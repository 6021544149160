.page-item {
  &:first-child {
    .page-link {
      @include border-radius($pagination-border-radius);
    }
  }

  &:last-child {
    .page-link {
      @include border-radius($pagination-border-radius);
    }
  }

  &.active {
    > .page-link {
      box-shadow: $pagination-active-box-shadow;
    }
  }
}

.page-link {
  border-radius: $pagination-border-radius;
  height: $pagination-border-radius;
  min-width: $pagination-border-radius;
  line-height: $pagination-border-radius;
  padding: 0;
  margin-left: $pagination-margin-x;
  margin-right: $pagination-margin-x;
}

.drawer {
  position: fixed;
  top: 0;
  bottom: 0;
  width: $drawer-width;
  max-width: 100%;
  background-color: $drawer-bg;
  box-shadow: $drawer-box-shadow;
  z-index: $drawer-z-index;
  transition: $drawer-transition;
  transform: translateX(0);
  will-change: transform;

  &-right {
    right: 0;
    border-left: $drawer-border-width solid $drawer-border-bg;
    &.drawer-collapsed {
      transform: translateX(calc(100% - #{$drawer-gutter}));
    }
  }
}

.drawer-btn {
  width: $drawer-btn-width;
  background-color: $drawer-btn-bg;
  border: $drawer-border-width solid $drawer-btn-border-bg;
  border-right: 0;
  border-radius: $drawer-btn-border-radius 0 0 $drawer-btn-border-radius;
  box-shadow: $drawer-btn-box-shadow;
  position: absolute;
  right: 100%;
  clip-path: inset(-0.625rem 0 -0.75rem -0.625rem);

  &-center {
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover,
  &:focus,
  &:active &:active:focus,
  &:active:hover,
  &.active:focus,
  &.active:hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: $drawer-btn-bg !important;
    border-color: $drawer-btn-border-bg;
    box-shadow: $drawer-btn-box-shadow !important;
  }
}

.drawer-btn-bar {
  background-color: $drawer-btn-border-bg;
  height: $drawer-btn-bar-height;
  width: $drawer-btn-bar-width;
}

.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  background-color: $drawer-overlay-bg;
  z-index: $drawer-overlay-z-index;
}

@include media-breakpoint-down(xs) {
  .drawer {
    $drawer-btn-left-gutter: 0.15rem;
  }
}

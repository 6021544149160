$circle-diameter: 1.2rem;

$circle-diameter-xs: $circle-diameter * 0.5;
$circle-diameter-sm: $circle-diameter * 0.75;
$circle-diameter-lg: $circle-diameter * 1.25;
$circle-diameter-xl: $circle-diameter * 1.5;

$circle-sizes: (
  'xs': $circle-diameter-xs,
  'sm': $circle-diameter-sm,
  'md': $circle-diameter,
  'lg': $circle-diameter-lg,
  'xl': $circle-diameter-xl,
);

@each $size, $value in $circle-sizes {
  .circle-#{$size} {
    @include circle-size($value);
  }
}

@each $color, $value in $theme-colors {
  .circle-#{$color} {
    background-color: $value;
    @include circle-variant($value, $value);
  }
}

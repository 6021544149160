//Color system

$transparent-active-base-bg: $gray-100;

// Components

$line-height-xs: 1rem;
$line-height-xl: 1.5;

$border-radius-xs: 0.15rem;
$border-radius-xl: 0.35rem;

// Typography
$font-size-xs: $font-size-base * 0.75;
$font-size-m: $font-size-base;
$font-size-l: $font-size-base * 1.25;
$font-size-xl: $font-size-base * 1.375;

$font-sizes: (
  xs: $font-size-xs,
  m: $font-size-m,
  l: $font-size-l,
  xl: $font-size-xl,
);

$icon-size-xs: $font-size-base * 1.125;
$icon-size-s: $font-size-base * 1.5;
$icon-size-m: $font-size-base * 2;
$icon-size-l: $font-size-base * 2.5;
$icon-size-xl: $font-size-base * 3.5;

$icon-sizes: (
  xs: $icon-size-xs,
  s: $icon-size-s,
  m: $icon-size-m,
  l: $icon-size-l,
  xl: $icon-size-xl,
);

// Navs

$nav-link-indicator-size: 0.688rem;

$nav-tabs-link-color: $gray-500;

// Pagination

$pagination-active-box-shadow: 0 0.125rem 0.125rem rgba(204, 197, 185, 0.5);
$pagination-margin-x: 0.125rem;

// Cards

$card-box-shadow: 0 0.375rem 0.625rem -0.25rem rgba(0, 0, 0, 0.15);

// Tooltips

$tooltip-box-shadow: 0rem 0.313rem 1.563rem 0rem rgba(0, 0, 0, 0.2);

// Modals

$modal-content-box-shadow: 0 0 0.938rem rgba(0, 0, 0, 0.15),
  0 0 0.063rem 0.063rem rgba(0, 0, 0, 0.1);

// Buttons + Forms

$input-btn-padding-y-xs: 0.2rem;
$input-btn-padding-x-xs: 0.3rem;
$input-btn-font-size-xs: $font-size-xs;
$input-btn-line-height-xs: $line-height-xs;

$input-btn-padding-y-xl: 0.625rem;
$input-btn-padding-x-xl: 1.25rem;
$input-btn-font-size-xl: $font-size-xl;
$input-btn-line-height-xl: $line-height-xl;

// Buttons

$btn-border-radius-xs: $border-radius-xs;
$btn-border-radius-xl: $border-radius-xl;

$btn-padding-y-xs: $input-btn-padding-y-xs;
$btn-padding-x-xs: $input-btn-padding-x-xs;
$btn-font-size-xs: $input-btn-font-size-xs;
$btn-line-height-xs: $input-btn-line-height-xs;

$btn-padding-y-xl: $input-btn-padding-y-xl;
$btn-padding-x-xl: $input-btn-padding-x-xl;
$btn-font-size-xl: $input-btn-font-size-xl;
$btn-line-height-xl: $input-btn-line-height-xl;

$btn-transparent-active-base-bg: $transparent-active-base-bg;

// Alerts

$alert-font-size: $font-size-base;
$alert-line-height: $line-height-base;
$alert-close-font-size: $font-size-base * 1.5;

$alert-padding-y-sm: 0.3125rem;
$alert-padding-x-sm: 0.9375rem;
$alert-font-size-sm: $font-size-sm;
$alert-line-height-sm: $line-height-sm;
$alert-border-radius-sm: $border-radius-sm;
$alert-close-font-size-sm: $font-size-base * 1.3125;

// Skeleton

$skeleton-bg: $gray-200;
$skeleton-highlight-bg: lighten($skeleton-bg, 3%);
$skeleton-bg-width: 12.5rem;
$skeleton-bg-height: 100%;
$skeleton-border-radius: 0.25rem;
$skeleton-animation-duration: 1.5s;

// Drawer

$drawer-bg: $white;
$drawer-border-bg: $border-color;
$border-width: 1px;
$drawer-border-width: $border-width;
$drawer-box-shadow: $card-box-shadow;
$drawer-width: 31.25rem;
$drawer-gutter: map-get(
  $map: $spacers,
  $key: 1,
);
$drawer-transition: all ease 0.3s;
$drawer-z-index: $zindex-modal;

$drawer-overlay-bg: rgba(0, 0, 0, 0.5);
$drawer-overlay-z-index: $zindex-modal-backdrop;

$drawer-btn-bg: $drawer-bg;
$drawer-btn-border-bg: $drawer-border-bg;
$drawer-btn-border-radius: $card-border-radius;
$drawer-btn-box-shadow: $card-box-shadow;
$drawer-btn-width: 1.5rem;
$drawer-btn-bar-height: 2.5rem;
$drawer-btn-bar-width: 0.25rem;

// List Group Item

$list-group-item-transparent-active-base-bg: $transparent-active-base-bg;

// Sidebar
$sidebar-logo-height: 2.938rem;
$sidebar-logo-transition: left ease 0.3s;

$sidebar-logo-mini-offset-expanded: 0.875rem;
$sidebar-logo-mini-offset-collapsed: 0.438rem;
$sidebar-logo-mini-width: 2.25rem;

$sidebar-logo-main-offset-expanded: 1.375rem;

$sidebar-image-nav-link-margin-x: 0.938rem;
$sidebar-image-nav-link-margin-y: 0.938rem;
$sidebar-image-nav-link-border-width: $border-width;
$sidebar-image-nav-link-border-color: rgba(33, 37, 41, 0.4);

$sidebar-image-nav-link-logo-width: 2.5rem;
$sidebar-image-nav-link-logo-height: 2.5rem;
$sidebar-image-nav-link-logo-margin-right: 0.75rem;
$sidebar-image-nav-link-logo-offset-left: 0.313rem;

$sidebar-image-nav-link-name-font-size: 0.875rem;
$sidebar-image-nav-link-name-translate-x: -1.563rem;

// Table skeleton
$table-skeleton-grey: #c4c4c4;
$table-skeleton-light-grey: #dbdbdb;

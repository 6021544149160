.table-header {
  border-bottom: solid 1px $table-border-color;
}

.table-body-container {
  border-top: none !important;
}

.table-row-background-color {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-body-container .table-body-row:not(:first-child) {
  border-top: solid 1px $table-border-color;
}

.table-row-clear-background-color {
  background-color: rgb(255, 255, 255) !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #808080 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #808080;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

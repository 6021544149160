.date-filter-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'completedLabel completedFrom completedTo'
    'expiresLabel expiresFrom expiresTo'
    'expiresDaysLabel expiresDaysValue .'
    'lastAccessedLabel lastAccessedFrom lastAccessedTo';
  column-gap: 8px;
  row-gap: 8px;
}

.completed-label {
  grid-area: completedLabel;
}

.completed-From {
  grid-area: completedFrom;
}

.completed-to {
  grid-area: completedTo;
}

.expires-label {
  grid-area: expiresLabel;
}

.expires-from {
  grid-area: expiresFrom;
}

.expires-to {
  grid-area: expiresTo;
}

.expires-days-label {
  grid-area: expiresDaysLabel;
}

.expires-days-value {
  grid-area: expiresDaysValue;
}

.last-accessed-label {
  grid-area: lastAccessedLabel;
}

.last-accessed-from {
  grid-area: lastAccessedFrom;
}

.last-accessed-to {
  grid-area: lastAccessedTo;
}

// Color system
$white: #fff;
$black: #000;
$blue: #223555;

$primary: $blue;
$info: #d1ecf1;

$custom-colors: (
  'transparent': transparent,
);

// Body
$border-color: rgba(33, 37, 41, 0.4);

// Typography
$font-family-sans-serif: 'Montserrat', 'Helvetica', Arial, sans-serif;

$enable-responsive-font-sizes: true;
$font-size-base: 1rem * 0.8;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;

//Components
$border-radius-sm: 0.1875rem;

// Navs
$nav-tabs-link-hover-border-color: transparent;
$nav-tabs-link-active-bg: transparent;
$nav-tabs-link-active-border-color: transparent;

// Navbar
$navbar-brand-padding-y: 0.5rem;

// Pagination
$pagination-bg: transparent;
$pagination-border-width: 0;
$pagination-border-radius: 1.875rem;

// Cards
$card-spacer-x: 1rem;
$card-border-radius: 0.75rem;
$card-border-width: 0;
$card-bg: $white;
$card-cap-bg: $card-bg;

// Tooltips
$tooltip-color: $black;
$tooltip-bg: $white;
$tooltip-opacity: 0.95;
$tooltip-padding-y: 0.5rem;
$tooltip-padding-x: 0.7rem;

// Modals
$modal-content-border-width: 0;
$modal-content-border-radius: 0.625rem;

// Alerts
$alert-bg-scale: -30%;
$alert-color-scale: 100%;

// Buttons + Forms
$input-btn-padding-y-sm: 0.3125rem;
$input-btn-padding-x-sm: 0.9375rem;
$input-btn-font-size-sm: 0.8571rem;

// Buttons
$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $input-btn-font-size-sm;

// Close
$close-text-shadow: none;
$close-font-weight: $font-weight-light;
$close-font-size: $font-size-base * 2;

// Table
$table-border-color: rgba(33, 37, 41, 0.4);
